export const seo = {
  url: 'platnosci',
  title: {
    pl: 'Omida Płatności | Faktury | Dokumenty | Rozliczenia',
  },
  desc: {
    pl: 'Wypełnij formularz, aby skontaktować się z naszym działem rozliczeń. Na wszystkie zapytania odpowiadamy w kolejności zgłoszeń. Uprzejmie prosimy o nieprzesyłanie ponownie zgłoszenia dotyczącego tej samej sprawy.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'tsl',
    'platnosci',
    'faktura',
    'rozliczenie',
  ],
}

export const intro = {
  title: {
    pl: 'Płatności',
  },
  desc: {
    pl: 'Wypełnij formularz, aby skontaktować się z naszym działem rozliczeń. Na wszystkie zapytania odpowiadamy w kolejności zgłoszeń. Uprzejmie prosimy o nieprzesyłanie ponownie zgłoszenia dotyczącego tej samej sprawy. Jeśli pytanie dotyczy więcej niż 1 faktury, prosimy o wpisanie pozostałych numerów faktur i zleceń w polu WIADOMOŚĆ.',
  },
}
